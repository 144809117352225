* {
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(0, 0, 0, 0);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(155, 155, 155, 0.7);
}
